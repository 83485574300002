import React,{ Component } from 'react';
import { View, Image, StyleSheet, Dimensions, ScrollView, Platform } from 'react-native';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import CommonFunction from '../utils/CommonFunction';
import ListStyles from '../styles/ListStyles';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class SafetyScreen extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.title = '安全への取り組み';
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      this.setHeader();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }  

  setHeader(){
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }
  
  render() {
    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container]}>
        <ScrollView 
          style={[ListStyles.scrollContainer,styles.container]} 
          scrollToOverflowEnabled={true} 
          scrollIndicatorInsets={{ right: 1 }}
        >      
        <View style={{}}>
          <View style={styles.section}>
            <Image style={styles.img01} source={require('../assets/images/safety/safety_01.png')} resizeMode="stretch"/>
          </View>
          <View style={styles.section}>
            <Image style={styles.img02} source={require('../assets/images/safety/safety_02.png')} resizeMode="stretch"/>
          </View>
          <View style={styles.section}>
            <Image style={styles.img03} source={require('../assets/images/safety/safety_03.png')} resizeMode="stretch"/>
          </View>
          <View style={styles.section}>
            <Image style={styles.img04} source={require('../assets/images/safety/safety_04.png')} resizeMode="stretch"/>
          </View>
          <View style={styles.section}>
            <Image style={styles.img05} source={require('../assets/images/safety/safety_05.png')} resizeMode="stretch"/>
          </View>
          <View style={styles.section}>
            <Image style={styles.img06} source={require('../assets/images/safety/safety_06.png')} resizeMode="stretch"/>
          </View>
          <View style={styles.section}>
            <Image style={styles.img07} source={require('../assets/images/safety/safety_07.png')} resizeMode="stretch"/>
          </View>
          <View style={styles.section}>
            <Image style={styles.img08} source={require('../assets/images/safety/safety_08.png')} resizeMode="stretch"/>
          </View>
          <View style={styles.section}>
            <Image style={styles.img09} source={require('../assets/images/safety/safety_09.png')} resizeMode="stretch"/>
          </View>
          <View style={styles.section}>
            <Image style={styles.img10} source={require('../assets/images/safety/safety_10.png')} resizeMode="stretch"/>
          </View>
          <View style={[styles.section,styles.section11]}>
            <Image style={styles.img11} source={require('../assets/images/safety/safety_11.png')} resizeMode="stretch"/>
          </View>
          </View>
        </ScrollView>
      </View>
      }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    ...Platform.select({
      ios:{
        backgroundColor:'#58d9b9'
      },
      android:{
        backgroundColor:'#58d9b9'
      },
      default:{
        
      }
    })
  },
  section:{
    flexDirection:'column', 
    alignItems:'center',
  },
  section11:{
    ...Platform.select({
      ios:{
        backgroundColor:'#1ab3b1'
      },
      android:{
        backgroundColor:'#1ab3b1'
      },
      default:{
        
      }
    })
  },
  img01:{
    marginTop:0,
    alignItems:'center',
    //height:windowWidth<375? 151 : 177,
    height:windowWidth<375? 151 : windowWidth/2.118,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
  img02:{
    marginTop:0,
    alignItems:'center',
    //height:windowWidth<375? 98 : 115,
    height:windowWidth<375? 98 : windowWidth/3.26,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
  img03:{
    marginTop:0,
    alignItems:'center',
    //height:windowWidth<375? 319 : 374,
    height:windowWidth<375? 319 : windowWidth,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
  img04:{
    marginTop:0,
    alignItems:'center',
    //height:windowWidth<375? 258 : 302,
    height:windowWidth<375? 258 : windowWidth/1.241,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
  img05:{
    marginTop:0,
    alignItems:'center',
    //height:windowWidth<375? 281 : 329,
    height:windowWidth<375? 281 : windowWidth/1.139,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
  img06:{
    marginTop:0,
    alignItems:'center',
    //height:windowWidth<375? 350 : 410,
    height:windowWidth<375? 350 : windowWidth/0.912,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
  img07:{
    marginTop:0,
    alignItems:'center',
    //height:windowWidth<375? 116 : 136,
    height:windowWidth<375? 116 : windowWidth/2.757,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
  img08:{
    marginTop:0,
    alignItems:'center',
    //height:windowWidth<375? 409 : 479,
    height:windowWidth<375? 409 : windowWidth/0.782,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
  img09:{
    marginTop:0,
    alignItems:'center',
    //height:windowWidth<375? 63 : 74,
    height:windowWidth<375? 63 : windowWidth/5.067,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
  img10:{
    marginTop:0,
    alignItems:'center',
    //height:windowWidth<375? 389 : 456,
    height:windowWidth<375? 389 : windowWidth/0.822,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
  img11:{
    marginTop:0,
    marginBottom:50,
    alignItems:'center',
    //height:windowWidth<375? 190 : 222,
    height:windowWidth<375? 190 : windowWidth/1.689,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        //width:windowWidth<375? 320 : 375,
        width:'100%',
      }
    })
  },
});
