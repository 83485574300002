import React, {Component, Fragment} from 'react';
import { 
  StyleSheet, Text, View, Image, Platform,TouchableOpacity, TextInput, ImageBackground, 
  SectionList, Dimensions , KeyboardAvoidingView, PixelRatio, Linking
} from 'react-native';
import { Ionicons, Entypo } from '@expo/vector-icons';
import HeadStyle from '../../styles/HeadStyles';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import CommonFunction from '../../utils/CommonFunction';
import BottomMenu from '../../components/common/BottomMenu';
import BottomMenuStyles from '../../styles/BottomMenuStyles';
import LoadMore from '../../components/screens/LoadMore';
import MailBoxApi from "../../services/api/MailBox05Api";
import PortalStyle from '../../styles/PortalStyles';
import Moment from 'moment';
import { Formik } from 'formik';
import { UploadChatImageInput } from '../../components/form/FormInput';
import UserUtil from "../../utils/UserUtil";
import Constants from '../../constants/Constants';
import RightSlidePopup from '../../components/common/RightSlidePopup';
import UserVerify from '../../utils/UserVerify';
import Popup from '../../components/common/Popup';
import AgeVerify2 from '../../components/common/AgeVerify2';
import PaymentVerify2 from '../../components/common/PaymentVerify2';
import WebUtil from '../../utils/WebUtil';
import HeaderOptions from '../../constants/HeaderOptions';
import NoPoint from '../../components/common/NoPoint';
import ErrorCode from '../../constants/ErrorCode';
import PopupWrapper from '../../components/common/PopupWrapper';
import FavoriteSetting from '../../components/common/FavoriteSetting';
import StorageHelper from '../../utils/StorageHelper2';
import sectionListGetItemLayout from 'react-native-section-list-get-item-layout';
import FavoriteNameSetting from '../../components/common/FavoriteNameSetting';
import ChatImageDelete from '../../components/common/ChatImageDelete';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';
import ChatWarning from '../../components/common/ChatWarning';
import Hyperlink from 'react-native-hyperlink';
import Carousel from 'react-native-snap-carousel';
import CampaignEndPopup from '../../components/common/CampaignEndPopup';


const { checkUndefined, getJPWeekday } = CommonFunction;
const { enableScrolling, disableScrolling, resizeImage } = WebUtil;
const screenWidth = Dimensions.get("window").width;
let screenHeight = Dimensions.get("window").height;
if(Platform.OS==='web'){
  if(screenHeight < document.documentElement?.clientHeight)
    screenHeight = document.documentElement.clientHeight;
}
const chatAreaHeight = screenHeight - HeaderOptions.headerTopMenuHeight - HeaderOptions.bottomChatHeight;
const inputAccessoryViewID = "inputAccessoryView1";
let keyboardVerticalOffset = 85;
if(screenWidth<=375 && screenHeight<=667) keyboardVerticalOffset = 64;

export default class ChatRoomScreen extends LoadMore {
  constructor() {
    super();
    this.BottomMenuRef = React.createRef();
    this.formik = React.createRef();
    this.imageRef = React.createRef();
    this.scrollRef = React.createRef();
    this.AgeVerify2Ref = React.createRef();
    this.PaymentVerify2Ref = React.createRef();
    this.NoPointRef = React.createRef();
    this.FavoriteSettingRef = React.createRef();
    this.FavoriteNameSetting = React.createRef();
    this.ChatImageDeleteRef = React.createRef();
    this.actionEnabled = React.createRef();
    this.swiper = React.createRef();
    this.CampaignEndPopupRef = React.createRef();
    this.initialValues = {
      message: '',
      imageFile: ''
    }
    this.chatBoxArea = {
      lineHeight: 20,
      minHeight: 25,
      maxHeight: 85
    }
    this.submitField = null;
    this.contentOffsetY = 0;
    this.itemHeights = {};
    this.prevRoute = ['Profile','SameProfile']; // not show bottom tab
    this.headerHeight = 45; // section header height
    this.sendMessageAfterMatchingFlg = 0;
  

    this.state = {
      ...this.state,
      showPopUpAge: false,
      showPopUpPayment: false,
      editable: false,
      chatBoxAreaHeight: this.chatBoxArea.minHeight,
      profile: {},
      exchangeMessageFlag: 0,
      paymentFlag: 0,
      allowSendFirstMessageFlag: 0,
      matchFlg: 1,
      good: 0,
      point: 0,
      reloadProfileFlg: 0,
      messageBoxId: '',
      favorite: '',
      sendCheckFlag: 1,
      addHeight: 0,
      itemRatio: 0.75,
      itemMargin: '8%',
      scrollEnabled:true,
      privilegeEndFlag: 0,
      ikuyoStatus: 0,
      sendGoodFlag: 0,
    }
    this.keyFavoriteSearch = 'FavoriteSearch';
    this.namesId = { single: 1, double: 2, triple: 3 };
    this.favoriteNames = {
      single: '',
      double: '',
      triple: '',
    };
    this.offset = 0;
    this.contentOffset = 5;
    this.ageVerficationPopup = React.createRef();
  }

  async componentDidMount() {
    super.componentDidMount();
    if(this._isMounted){
      const {navigation, BottomTabNavigation } = this.props;
      this.navigation = navigation;
      this.BottomTabNavigation = BottomTabNavigation;
      
      this.setHeader();
      CommonFunction.setMainBottomTab(this.BottomTabNavigation, "none");
      this.userProfile = await UserUtil.getUserProfileLocal(this.appContext.appState);
      
      this.subscribeFocus = this.navigation?.addListener('focus', async() => {
        const checkAccessTimeFlag = await CommonFunction.checkAccessTime();
        if(checkAccessTimeFlag){
          CommonFunction.setMainBottomTab(this.BottomTabNavigation, "none");
        }
        this.actionEnabled.current = false;
      });
    }
  }
  
  componentWillUnmount() {
    super.componentWillUnmount();
    this.checkBottomTab();
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }

  checkBottomTab=()=>{
    if(CommonFunction.checkBottomTab(this.navigation, this.prevRoute)){
      CommonFunction.setMainBottomTab(this.BottomTabNavigation, "flex");
    }
  }

  getMailBoxDetail = async(page)=>{
    if(this._isMounted){
      try{
        const memberId = this.props?.route?.params?.memberId;
        const params = { page: page, targetMemberId: memberId };
        const response = await MailBoxApi.MailBoxDetail(params);
        //check response
        if(!CommonFunction.checkResponseStatus(response)) return;

        return response;
      }catch(e){
        if(__DEV__) console.log(e);
      }
    }
  }

  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.appContext)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account?.activated, 
      navigation: this.navigation,
      account: this.state.account
    });
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.appContext)) return true;

    if(this.state.account?.ageVerficationStatus!=Constants.AgeVerficationStatus.approve){
      CommonFunction.checkAccessTime().then(response=>{
        if(response===true){
          this.AgeVerify2Ref?.show();
        }
      });
      return false;
    }
    return true;
  }

  checkAgeNoPopup = ()=>{
    if(UserVerify.checkAgeAppState(this.appContext)) return true;
    return false;
  }

  checkAgePartner = ()=>{
    const result = UserVerify.checkAgePartner({
      ageVerficationStatus: this.state.profile?.verified,
      navigation: this.navigation
    });
    return result;
  }

  checkPayment = ()=>{
    if(this.checkMatchFlg()){
      if(!this.checkEditAble()){
        CommonFunction.checkAccessTime().then(response=>{
          if(response===true){
            if(this.checkCampaign()){
              this.PaymentVerify2Ref?.show();
            }
          }
        });
        return false;
      }
    }
    return true;
  }

  checkPaymentPopup = ()=>{
    if(this.checkMatchFlg()){
      if(this.state?.paymentFlag==Constants.SubscriptionStatus.notApprove){
        CommonFunction.checkAccessTime().then(response=>{
          if(response===true){
            if(this.checkCampaign()){
              this.PaymentVerify2Ref?.show();
            }
          }
        });
        return false;
      }
    }
    return true;
  }

  checkExchangeMessage = ()=>{
    if(this.state?.exchangeMessageFlag==Constants.ExchangeMessageStatus.notApprove){
      CommonFunction.checkAccessTime().then(response=>{
        if(response===true){
          this.messageExchangePopup?.showPopup();
        }
      });
      return false;
    }
    return true;
  }

// イククル連携会員で200通の送信済み
checkCampaign = ()=>{
  const privilegeEndFlag = this.state.privilegeEndFlag;
  const ikuyoStatus = this.state.ikuyoStatus;
  if ((ikuyoStatus==1)&&(privilegeEndFlag==1)) {
    this.showCampaignLimit();
    return false;    
  }
  return true;
}

showCampaignLimit = ()=>{
  this.CampaignEndPopupRef?.show();    
}

  getListFromApi = async(page)=>{
    if(this._isMounted){
      try{
        const mailBoxDetailResponse = await this.getMailBoxDetail(page);
        if(checkUndefined(mailBoxDetailResponse) && mailBoxDetailResponse?.status==1){
          const ikuyoStatus = mailBoxDetailResponse?.ikuyoStatus;
          const mailBoxDetail = mailBoxDetailResponse?.response;
          // set appContext value
          let account = UserUtil.getAccountAppState(mailBoxDetailResponse, this.appContext);
          this.setState({ account: account });
        
          let paymentFlag = 0, allowSendFirstMessageFlag = 0, profile={}, stateData = {};
          this.sendMessageAfterMatchingFlg = mailBoxDetail?.sendMessageAfterMatchingFlg;

          if(mailBoxDetail?.matchFlg == 1){
            if(this.sendMessageAfterMatchingFlg == 0){
              this.messageBoxTemplateList = await StorageHelper.getObjectData('messageBoxTemplateList');
            }
          }
          else{
            if(mailBoxDetail?.sendMessageFlg==0){
              let templateItem = await StorageHelper.getData('messageBoxTemplateItem')
              if(templateItem){
                let list = [templateItem];
                this.messageBoxTemplateList = list;
              }
            }
          }

          if((this.messageBoxTemplateList && this.messageBoxTemplateList.length > 0)){
            if(this.messageBoxTemplateList.length == 1){
              this.setState({ itemRatio: 0.92, itemMargin: '6.7%'})//'start''center'
            } else{
              this.setState({ itemRatio: 0.75, itemMargin: '8%'})
            }
            this.setState({ addHeight: HeaderOptions.bottomChatHeight + 36 })
          } else{
            this.setState({ addHeight: HeaderOptions.bottomChatHeight })
          }

          if(checkUndefined(mailBoxDetail)){
            if(this.userProfile?.sex==Constants.FEMALE){ // female
              paymentFlag = Constants.SubscriptionStatus.approve;
            }else{
              paymentFlag = account?.subscription?.status;
              if(paymentFlag==Constants.SubscriptionStatus.notApprove && mailBoxDetail?.sendMessageFlg==0){ // check first message
                allowSendFirstMessageFlag = 1;
              }
            }
          
            profile = mailBoxDetail?.profile;
            CommonFunction.mainImageUrlConver(profile);
            const list = mailBoxDetail?.list;
            if(checkUndefined(list)){
              let {newMessage, data} = this.getNewMessage(list);
              if(page > 1){
                stateData = data.concat(newMessage) 
              }else{
                stateData = newMessage;
              }
              this.no_more = (mailBoxDetail?.nextVal == 0)?1:0;
            }
          }

          this.setState({ 
            account: account,
            profile: profile,
            paymentFlag: paymentFlag,
            data: stateData,
            exchangeMessageFlag: mailBoxDetail?.exchangeMessageFlag,
            allowSendFirstMessageFlag: allowSendFirstMessageFlag,
            matchFlg: mailBoxDetail?.matchFlg,
            good: mailBoxDetailResponse?.good,
            point: mailBoxDetailResponse?.point,
            sendMessageFlg: mailBoxDetail?.sendMessageFlg,
            messageBoxId: mailBoxDetailResponse?.response.messageBoxId,
            favorite: mailBoxDetailResponse?.response.favorite,
            sendCheckFlag: mailBoxDetail?.sendCheckFlag,
            privilegeEndFlag:  mailBoxDetail?.privilegeEndFlag,
            ikuyoStatus:ikuyoStatus,
            sendGoodFlag: mailBoxDetail?.sendGoodFlag
          },()=>{
            // validate SMS
            if(!this.checkMatchFlg()){
              this.checkSMS();
            }
            this.setHeader();
            this.setState({
              editable: this.validateEditable()
            });
          });
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({
          loading: false,
          isRefreshing: false
        });
      }
    }
  }

  getNewMessage = (chatList)=>{
    let { data } = this.state;
    if(data?.length==0) return {newMessage: chatList, data: []};

    let newMessage = [];
    if(chatList!=undefined){
      chatList.map((list) => { 
        const checkIndex = data?.findIndex(
          item => item.messageId === list.messageId
        );
        if(checkIndex==-1){ // new message
          newMessage.push(list);
        }else{ // update message
          if(JSON.stringify(list)!=JSON.stringify(data[checkIndex])){
            data[checkIndex] = list;
          }
        }
      });
      if(__DEV__) console.log('new message',newMessage);
    }

    return {
      newMessage: newMessage, 
      data: data
    };
  }

  updateMessage = async()=>{
    try{
      const mailBoxDetailResponse = await this.getMailBoxDetail(1);
      if(checkUndefined(mailBoxDetailResponse) && mailBoxDetailResponse?.status==1){
        const mailBoxDetail = mailBoxDetailResponse?.response;
        let account = UserUtil.getAccountAppState(mailBoxDetailResponse, this.appContext);
        if(mailBoxDetail){
          const list = mailBoxDetail?.list;
          const exchangeMessageFlag = mailBoxDetail?.exchangeMessageFlag;
          const sendMessageFlg = mailBoxDetail?.sendMessageFlg;
          const matchFlg = mailBoxDetail?.matchFlg;
          const paymentFlag = account?.subscription?.status;
          const privilegeEndFlag = mailBoxDetail?.privilegeEndFlag;
          this.setState({ matchFlg: matchFlg, paymentFlag: paymentFlag, privilegeEndFlag: privilegeEndFlag });

          this.sendMessageAfterMatchingFlg = mailBoxDetail?.sendMessageAfterMatchingFlg;

          if(this.checkMatchFlg()){
            if(this.sendMessageAfterMatchingFlg == 0){
              this.setState({ addHeight: HeaderOptions.bottomChatHeight + 36 })
            } else{
              this.setState({ addHeight: HeaderOptions.bottomChatHeight })
            }
          }else{
            if(sendMessageFlg == 0){
              this.setState({ addHeight: HeaderOptions.bottomChatHeight + 36 })
            } else{
              this.setState({ addHeight: HeaderOptions.bottomChatHeight })
            }
          }

          let { newMessage, data } = this.getNewMessage(list);
          if(newMessage?.length>0) { 
            data = newMessage.concat(data);
          }

          if(this.userProfile?.sex==Constants.MALE){
            if(matchFlg==1){
              if(!this.checkEditAble()){
                this.setState({ editable: false });
              }else{
                this.setState({ editable: true });
              }
            }
          }
        
          this.setState({ 
            data: data,
            exchangeMessageFlag: exchangeMessageFlag,
            sendMessageFlg: sendMessageFlg
          });
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  goBack = ()=>{
    this.checkHideUser(this.state.favorite);
    const prevRouteName = CommonFunction.getPrevRoute(this.navigation);
    if(this.prevRoute?.indexOf(prevRouteName)!=-1){
      let { appState, setAppState } = this.appContext;
      appState.reloadProfileFlg = this.state.reloadProfileFlg;
      setAppState(appState);
    }
    this.navigation?.goBack();
  }

  setHeader = ()=>{
    this.navigation.setOptions({
      header: () => (
        <View style={[HeadStyle.customHeader2,HeadStyle.appHeaderTop]}>
          <TouchableOpacity onPress={() =>{
            this.goBack();
          }}>
            <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack} />
          </TouchableOpacity>
          <View>
            <Image source={require('../../assets/images/logo-txt.png')} style={{width:screenWidth<375? 56:64,height:screenWidth<375? 21:24,}} resizeMode="contain"/>  
          </View>
          <View style={{flex:3, flexDirection:'row',width:'100%',justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center'}}>
          {this.state.profile && 
          <>
            <TouchableOpacity onPress={()=>{
              this.navigation.navigate('Profile',{memberId: this.state.profile?.memberId, type: this.props?.route?.params?.type, button:0})}}>
              {this.state?.profile?.tmMainImageUrl != null &&
              <Image style={styles.thumbAvatar} source={{uri : this.state?.profile?.tmMainImageUrl}} />  
              }  
            </TouchableOpacity>
            <Text style={[HeadStyle.headerTitlePage,{marginRight:8,marginLeft:8, fontSize:18, fontWeight:'normal'}]} numberOfLines={1}>{this.state.profile?.nickName}</Text>
          </>
          }
          </View>
          <View style={{flex:1,width:'100%', justifyContent:'center',alignContent:'flex-end', alignItems:'flex-end', alignSelf:'center'}}>  
            <TouchableOpacity onPress={ () => {
              // validate SMS
              if(!this.checkSMS()) return;
              // validate Age
              if(!this.checkAge()) return;
              if(this.BottomMenuRef.showPopUp)
                this.BottomMenuRef?.showPopUp(true);
            }}>
              <Ionicons name="ellipsis-horizontal" style={styles.icShowPopup} />
            </TouchableOpacity>
          </View>
        </View>
      )
    });
  }

  makeSectionData = (data) => {
    let sectionList = [];
    if(data!=undefined && data?.length>0){
      data.map((chatData) => {      
        let date = Moment(chatData.dateTime).format('YYYY-MM-DD');
        const checkIndex = sectionList?.findIndex(
          item => date === item.title
        );
        if(checkIndex==-1){
          let value = {title: date, data: [chatData]}
          sectionList.push(value);
        }else{
          sectionList[checkIndex].data.push(chatData);
        }
      });
    }

    return sectionList;
  }

  scrollToBottom = ()=>{
    if(this.contentOffsetY > 0){
      const data = this.makeSectionData(this.state.data);
      if(data?.length >0){
        const sectionIndex = 0;
        const itemIndex = 0;
        this.scrollRef?.scrollToLocation({
          sectionIndex: sectionIndex,
          itemIndex: itemIndex,
          viewPosition: 0
        });
      }
    }
  }

  resetForm = ()=>{
    if(this.submitField=='message'){
      this.formik?.setValues(this.initialValues);
    }else{
      this.formik?.setValues({imageFile: ''});
    }
  }

  setTemplates = async(message, MatchFlg)=>{
    if(!message){
      return;
    }
    if(MatchFlg){
      if(!this.messageBoxTemplateList){
        let list = [message]
        this.messageBoxTemplateList = list;
      }else{
        let isEequal = false;
        for (var i= 0; i < this.messageBoxTemplateList.length; i++) {
          if(message == this.messageBoxTemplateList[i]){
            this.messageBoxTemplateList.splice(i, 1);
            // isEequal = true;
          }
        }
        // if(!isEequal){
          this.messageBoxTemplateList.unshift(message);
        // }
      }
      if(this.messageBoxTemplateList.length > 5){
        this.messageBoxTemplateList.pop();
      }
      await StorageHelper.storeObjectData('messageBoxTemplateList', this.messageBoxTemplateList)
    } else{
      await StorageHelper.storeData('messageBoxTemplateItem', message)
    }
  }

  handleSubmit = async(values) => {
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 2000)

    let params = {
      targetMemberId: this.state.profile?.memberId,
      messageType: null,
      body: '',
      imageFile: null
    }

    const message = values.message;
    const imageFile = values.imageFile;
    let isValid = true;
    if(this.submitField=='message'){  // send message
      let checkMessage = CommonFunction.checkMessage(message);
      if(message==undefined || checkMessage==""){
        isValid = false;
        this.errorPopup?.showPopup();
        return;
      }
      params.body = message;
      params.messageType = 2;
    }else if(this.submitField=='imageFile'){  // send file
      if(imageFile!=undefined){
        const [, type] = imageFile.split(';')[0].split('/');
        const resp = await fetch(imageFile);
        const fblob = await resp.blob();
        const maxWidth = 1280;
        const config = {
          file: fblob,
          maxWidth: maxWidth
        };

        if(Platform.OS==='web'){
          params.imageFile = await resizeImage(config)
          if(__DEV__) console.log('resize file size',params.imageFile.size);
        }else{
          const resizeImageFile = await CommonFunction.resizeImageApp(imageFile, maxWidth);
          if(resizeImageFile){
            const type = resizeImageFile?.type;
            const mainPath = resizeImageFile?.mainPath;
            const postFile = {
              uri: mainPath,
              type: fblob.type,
              name: `profile.${type}`
            };
            params.imageFile = postFile;
          }
        }
        params.messageType = 4;
      }
    }

    // call API
    if(isValid){
      try{
        CommonFunction.overLay();
        const sendMailResponse = await MailBoxApi.SendMail(params,0);
        if(sendMailResponse?.status==1){
          if(this.state.sendMessageFlg==0){ // not send message before
            this.setState({reloadProfileFlg: 1});
          }


          if(this.checkMatchFlg()){
            if(this.sendMessageAfterMatchingFlg == 0){
              this.setTemplates(values.message, true);
            }
          } 
          else{
            if(this.state.sendMessageFlg==0){
              this.setTemplates(values.message, false);
            }
          }

          // first message for male
          if(this.state?.allowSendFirstMessageFlag==1){
            this.setState({allowSendFirstMessageFlag: 0}, async()=>{
              await this.updateMessage();
            });
          }else{
            await this.updateMessage();
          }
          this.resetForm();
          this.scrollToBottom();

          // 無料キャンペーンチェック（メッセージ送信後）
          const ikuyoStatus = sendMailResponse?.ikuyoStatus;
          const response = sendMailResponse?.response;
          const limitFlg = response?.privilegeLimitFlg;
          if ((ikuyoStatus==1) && (limitFlg==1)) {
            this.showCampaignLimit();
          }

        }else{
          if(JSON.stringify(sendMailResponse)=='{}'){
            CommonFunction.hideOverlay();
            return;
          }
          
          if(CommonFunction.checkIsInvalidErrorCode(sendMailResponse)){
            const response = sendMailResponse?.response;
            const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
            const errorMessage = response?.errorMessage;
            if(errorCode==ErrorCode.MailBoxPostSendMail.NoPoint){
              this.NoPointRef.setMessage(errorMessage);
              this.NoPointRef.show();
            }else if(errorCode==ErrorCode.MailBoxPostSendMail.NoMember){
              PopupWrapper.showMessage('お相手が見つかりませんでした。一時停止あるいは退会された可能性があります。','warning');
            }else{
              PopupWrapper.showMessage(errorMessage, 'warning');
            }
          }
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        CommonFunction.hideOverlay();
      }
    }
  }
  
  updateTextAreaSize = (height) => {
    if(height<this.chatBoxArea.minHeight) {
      height = this.chatBoxArea.minHeight;
    }else if(height > this.chatBoxArea.maxHeight) {
      height = this.chatBoxArea.maxHeight;
    }
    this.setState({ chatBoxAreaHeight: height+10 });
  }

  validateForm = (values)=>{
    let checkMessage = CommonFunction.checkMessage(values.message);
    if(checkMessage=='') {
      return false;
    }
    return true;
  }
  
  setImageRef = (ref)=>{
    this.imageRef = ref;
  }

  handleScroll = (e)=>{
    this.contentOffsetY = e?.nativeEvent?.contentOffset?.y;
  }

  getItemLayout = sectionListGetItemLayout({
    // The height of the row with rowData at the given sectionIndex and rowIndex
    getItemHeight: (rowData, sectionIndex, rowIndex) => {
      const messageId = 'messageId'+rowData?.messageId;
      if(this.itemHeights[messageId]){
        return this.itemHeights[messageId]
      }
      return 0
    },

    // These three properties are optional
    getSeparatorHeight: () => 1 / PixelRatio.get(), // The height of your separators
    getSectionHeaderHeight: () => 0, // The height of your section headers
    getSectionFooterHeight: () => this.headerHeight, // The height of your section footers
  });

  validateAuthenImage = ()=>{
    if(!this.validateAuthen()) return false;
    if(!this.checkExchangeMessage()) return false;

    return true;
  }

  validateAuthen = ()=>{
    if(!this.checkSMS()) return false;
    if(!this.checkAge()) return false;
    // if(!this.checkAgePartner()) return false;
    
    if(this.userProfile?.sex==Constants.MALE){
      if(!this.checkPayment()) return false;
    }

    return true;
  }

  validateAuthenOtherMsg = ()=>{
    if(!this.checkSMS()) return false;
    if(!this.checkAge()) return false;
    if(!this.checkAgePartner()) return false;

    if(this.userProfile?.sex==Constants.MALE){
      if(!this.checkPaymentPopup()) return false;
    }

    return true;
  }

  showAgeVerficationPopup = ()=>{
    this.ageVerficationPopup?.showPopup();
  }

  validateEditable = ()=>{
    if(this.state.account?.activated!=Constants.ActivatedStatus.approve) return false; //check SMS
    if(this.state.account?.ageVerficationStatus!=Constants.AgeVerficationStatus.approve) return false; //check Age
    // if(this.state.profile?.verified!=2) return false; // check Age partner

    if(this.userProfile?.sex==Constants.MALE){
      if(this.checkMatchFlg()){
        // check payment
        if(!this.checkEditAble()) return false;
      }
    }

    return true;
  }

  checkEditAble = ()=>{
    if(this.state.sendCheckFlag==0){
      if(this.state?.paymentFlag==Constants.SubscriptionStatus.notApprove){
        return false;
      } else{
        return true;
      }
    }
    if(this.state?.paymentFlag==Constants.SubscriptionStatus.notApprove && this.state?.allowSendFirstMessageFlag==0) {
      return false;
    } else return true;
  }

  checkMatchFlg = ()=>{
    if(this.state.matchFlg==1) return true;
    else return false;
  }

  setFavoriteSetting = async() => {
    const favoriteNameListResponse = await MailBoxApi.FavoriteNameList();
    if(!CommonFunction.checkResponseStatus(favoriteNameListResponse)) return;    
    if(favoriteNameListResponse?.status==1){
      const favoriteItems = favoriteNameListResponse?.response?.favorite.list;
      this.favoriteNames.single = favoriteItems[0].name;
      this.favoriteNames.double = favoriteItems[1].name;
      this.favoriteNames.triple = favoriteItems[2].name;
      const params = { targetMessageBoxId: this.state.messageBoxId, targetFavorite: this.state.favorite, favoriteNames: this.favoriteNames};
      this.FavoriteSettingRef?.show(params);
    }
  }

  favoriteSettingUpdata = (params) => {
    // お気に入りの星の数のみ更新
    this.setState({ favorite: params.targetFavorite });
    this.checkHideUser(params.targetFavorite);
  }

  checkHideUser = async(changeFavorite)=>{
    let favorite = await StorageHelper.getData(this.keyFavoriteSearch);
    const memberId = this.state.profile?.memberId;
    const {appState, setAppState} = this.context;
    if (favorite!=0) {
      if (favorite!=changeFavorite) {
        appState.setChatHideMemberId = memberId;
        setAppState(appState);
      } else {
        appState.setChatHideMemberId = null;
        setAppState(appState);
      }
    } else {
      const params = { targetMemberId: memberId, targetFavorite: changeFavorite };
      appState.setFavoritUpdate = params;
      setAppState(appState);
    }
  }

  favoriteNameSetting = (getParams)=>{
    const params = {selected: getParams.selected, slectNameChange: getParams.slectNameChange, favoriteNames: this.favoriteNames};
    this.FavoriteNameSettingRef?.show(params);
  }

  favoriteNameUpdata = (getParams)=>{

    if (getParams.slectNameChange!=undefined) {
      if (getParams.slectNameChange == this.namesId.single) {
        this.favoriteNames.single = getParams.updataFavoriteName;
      } else if(getParams.slectNameChange == this.namesId.double) {
        this.favoriteNames.double = getParams.updataFavoriteName;
      } else if(getParams.slectNameChange == this.namesId.triple) {
        this.favoriteNames.triple = getParams.updataFavoriteName;
      }    
    }
    const params = {targetMessageBoxId: this.state.messageBoxId, targetFavorite: getParams.selected, favoriteNames: this.favoriteNames};
    this.FavoriteSettingRef?.show(params);
  }

  deleteChatImage = (getParams) => {
    if (getParams.selectItem.messageType==4) {
      const params = {inputItem: getParams.selectItem};
      this.ChatImageDeleteRef?.show(params);    
    }
  }

  chatListUpdata = (getParams)=>{
    const deleteItem = getParams.deleteItem;
    const imageDelFlg = deleteItem.deleteFlag;
    const messageId = deleteItem.messageId;
    if (Number(imageDelFlg)==0) {
      this.imageDeleteUpdata(messageId);
    }
  }

  imageDeleteUpdata = (setMessageId) => {
    if(checkUndefined(setMessageId)){
      try{
        let { data } = this.state;
        if(data.length > 0){
          data = data.map((item) => (item?.messageId == setMessageId ? Object.assign(item, { deleteFlag: 1 }) : item));
          this.setState({data: data});
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
      }
    }
  }

  _renderTemplateItem = ({ item, index }, parallaxProps) => {
    return (
      
      <View style={[styles.templateItem,{marginLeft:this.state.itemMargin, marginRight:this.state.itemMargin}]}>
        <TouchableOpacity onPress={()=>{
          if(this.validateAuthen()){
            this.formik?.setValues({message: item});
          }
        }}>
        <Text numberOfLines={1} style={styles.templateItemText}>{item}</Text>
        </TouchableOpacity>
      </View>
    );
}


onTouchStart = ()=>{
  this.scrollDirection = '';
}

//web
onTouchRelease = ()=>{
  if(this.scrollDirection=='left'){
    this.swiper?.snapToPrev();
  }else if(this.scrollDirection=='right'){
    this.swiper?.snapToNext();
  }
  this.scrollDirection ='';
}



onSnapToItem = (index)=>{
}


onCarouselScroll=(event) => {
  if(Platform.OS==='web'){
    const currentOffset = event.nativeEvent?.contentOffset?.x;
    if(currentOffset!=this.offset && Math.abs(currentOffset-this.offset)>this.contentOffset){
      this.scrollDirection = currentOffset > this.offset ? 'right' : 'left';
      this.offset = currentOffset;
    }
  }
}


  render() {
    if (this.state.loading) return <ActivityIndicator />

    let noMachhMessage =  'アピールのメッセージを送りましょう！';
    if(this.state.sendGoodFlag!=1){
      noMachhMessage =  'メッセージを返してやり取りを始めましょう！';
    }

    return (
    <SafeAreaInsetsContext.Consumer>
    {(insets) =>
      <KeyboardAvoidingView 
        style={[styles.container,SafeAreaStyles(insets).containerMargin,{flexGrow:1}]} 
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        contentContainerStyle={{ flex: 1 }}
        keyboardVerticalOffset={keyboardVerticalOffset}
      >
        <AgeVerify2 ref={ref=>this.AgeVerify2Ref=ref} />
        <PaymentVerify2 ref={ref=>this.PaymentVerify2Ref=ref} />
        <CampaignEndPopup ref={ref=>this.CampaignEndPopupRef=ref} />
        <NoPoint ref={ref=>{this.NoPointRef=ref}} navigation={this.navigation} />
        <FavoriteSetting ref={ref=>this.FavoriteSettingRef=ref} favoriteUpdata={this.favoriteSettingUpdata} nameSetting={this.favoriteNameSetting} />
        <FavoriteNameSetting ref={ref=>this.FavoriteNameSettingRef=ref} filterUpdata={this.favoriteNameUpdata} />
        <ChatImageDelete ref={ref=>this.ChatImageDeleteRef=ref} chatListUpdata={this.chatListUpdata} />
        <ChatWarning memberId={this.state.profile?.memberId} navigation={this.navigation} show={true} />
        <View style={{justifyContent:'space-between', flex:1}}>
          {this.state.matchFlg!=1?
          <View style={{maxWidth: screenWidth}}>
            <View style={styles.matchingContainer}>
              <Text style={styles.noMatchTitle}>まだマッチングしていません</Text>
              <Text style={styles.matchingTxt}>{noMachhMessage}</Text>
            </View>
          </View>
          :
          null
          }
          <View style={styles.appChatArea}>
            <SectionList
              contentContainerStyle={{flexGrow:1, justifyContent: 'flex-end'}}
              ref={ref=>this.scrollRef=ref}
              style={{backgroundColor:'#ffffff', ...Platform.select({web:{flexBasis:0}}) }}
              sections={this.makeSectionData(this.state.data)}
              initialNumToRender={15}
              renderItem={({ item,index }) => (
                <View onLayout={object => {
                  const messageId = 'messageId'+item.messageId;
                  this.itemHeights[messageId] = object.nativeEvent.layout.height;
                  }}>
                {item.messageOwner==1 ?
                 <View style={{flex:1, justifyContent:'flex-end'}}>
                  <MyMessage item={item} index={index} deleteChatImage={this.deleteChatImage} />
                 </View>
                  :
                  <View style={{flex:1, justifyContent:'flex-start'}}>
                    <OtherMessage index={index} profile={this.state.profile} item={item} paymentFlag={this.state.paymentFlag} account={this.state.account} navigation={this.navigation} sex={this.userProfile?.sex} validateAuthen={this.validateAuthenOtherMsg} showAgeVerficationPopup={this.showAgeVerficationPopup} checkSMS={this.checkSMS} />
                  </View>
                }
                </View>
              )}
              numColumns={1}
              keyExtractor={(item, index) => index.toString()+item.messageId}
              renderSectionFooter={({ section: { title } }) => (
                <View style={styles.date}>
                  <Text style={styles.txtDate}>{Moment(title).format("MM/DD")}({getJPWeekday(Moment(title).day())})</Text>
                </View>
              )}
              ListFooterComponent={this.renderHeader.bind(this)}
              onEndReachedThreshold={0.2}
              onEndReached={this.handleLoadMore.bind(this)}
              inverted={true}
              onScroll={this.handleScroll}
              getItemLayout={this.getItemLayout}
            />
          </View>

          <Formik
            initialValues={this.initialValues}
            onSubmit={values => this.handleSubmit(values) }
            innerRef={p => (this.formik = p)}
          >
          {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
            <Fragment>
              <View style={[styles.bottomTab, {height:this.state.addHeight}]}>
              {this.state.addHeight > HeaderOptions.bottomChatHeight &&
                <Carousel
                  ref={swiper => {this.swiper=swiper}}
                  activeSlideAlignment={"start"}
                  data={this.messageBoxTemplateList}
                  renderItem={this._renderTemplateItem}
                  itemWidth={screenWidth * this.state.itemRatio}
                  sliderWidth={screenWidth * 1.0}
                  initialNumToRender={10}
                  inactiveSlideScale={1}
                  inactiveSlideOpacity={1}
                  activeSlideOffset={1}
                  enableSnap={Platform.OS==='web'?false:true}
                  onScroll={Platform.OS==='web'?this.onCarouselScroll:""}
                  extraData={this.messageBoxTemplateList}
                  keyExtractor={(item, index) => item.toString()+index}
                  firstItem={0}
                  onTouchStart={()=>{
                    // web
                    if(Platform.OS==='web'){
                      this.onTouchStart();
                    }
                  }}
                  onTouchRelease={(event)=>{
                    // web
                    if(Platform.OS==='web'){
                      this.onTouchRelease(event);
                    }
                  }}
                  onSnapToItem = { index => {
                    // web
                    if(Platform.OS!=='web'){
                      this.onSnapToItem(index);
                    }
                  }}
                />
                }
                {!this.state.editable ?
                  <TouchableOpacity activeOpacity={1} onPress={()=>this.validateAuthen()} style={styles.textareaBtnBottom}>
                    <Text style={{color:'#9aa5a7',height:75}}>メッセージを入力</Text>
                  </TouchableOpacity>
                :
                <View style={styles.textareaBtnBottom}>
                  <TextInput style={styles.textAreaStyle} 
                    multiline={true} 
                    placeholder="メッセージを入力"
                    placeholderTextColor="#9aa5a7"
                    //onContentSizeChange={(e) => this.updateTextAreaSize(e.nativeEvent.contentSize.height)}
                    onChangeText={handleChange('message')}
                    onBlur={() => {
                      setFieldTouched('message')
                      // if(Platform.OS=='web')enableScrolling();
                    }}
                    value={values.message}
                    onFocus={()=>{ 
                      this.validateAuthen();
                      // if(Platform.OS=='web')disableScrolling();
                    }}
                    editable={this.state.editable}
                    inputAccessoryViewID={inputAccessoryViewID}
                    />
                </View>
                }
                
                <View style={{ flexDirection:'row',width:'90%'}}>
                  <View style={{flex:1, justifyContent:'flex-start', flexDirection:'row'}}>
                    <TouchableOpacity style={styles.btnBtnBottom} onPress={()=>{
                      this.setFavoriteSetting();
                    }}>
                      <Image style={styles.btnBtnFav} source={require('../../assets/images/favorite/favorite_1.png')}/>
                    </TouchableOpacity>
                    <View style={styles.btnBtnBottom}>
                      <UploadChatImageInput fieldName="imageFile" setRef={this.setImageRef} handleSubmit={handleSubmit} 
                      setSubmitField={()=>{
                        this.submitField = 'imageFile';
                      }} 
                      onPress={this.validateAuthenImage} />
                    </View>
                  </View>
                  <View style={{flex:1, alignItems:'flex-end'}}>
                    <View style={styles.btnBtnBottom}>
                    <TouchableOpacity 
                      disabled={!this.validateForm(values)} 
                      style={[styles.btnBtnSendMsg,{opacity:!this.validateForm(values)?0.5:1}]} 
                      onPress={()=>{
                        if(this.validateAuthen()){
                          this.submitField = 'message';
                          handleSubmit();
                        }
                      }}>
                        <Image style={styles.icBtnBottom} source={require('../../assets/images/icon/paper-plane.png')}/>
                        <Text style={styles.txtBtnSendMsg}>送信</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>

              </View>
            </Fragment>
            )}          
          </Formik>
        </View>
      
        {/** popup menu */}
        <BottomMenu ref={ref=>this.BottomMenuRef=ref}>
          <TouchableOpacity  activeOpacity={0.85} style={BottomMenuStyles.listPopupFirst} onPress={()=>{
            this.BottomMenuRef?.hide();
            this.navigation.navigate('Report',{memberId: this.state.profile?.memberId});
          }}>
            <Text style={[BottomMenuStyles.txtListPopup]}>通報する</Text>
          </TouchableOpacity>
          <TouchableOpacity  activeOpacity={0.85} style={BottomMenuStyles.listPopupLast} onPress={()=>{
            this.BottomMenuRef?.hide();
            this.navigation.navigate('ChatHideSetting',{memberId: this.state.profile?.memberId});
          }}>
            <Text style={[BottomMenuStyles.txtListPopup, BottomMenuStyles.txtColorRed]}>非表示・ブロックの設定</Text>
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.btnListPopupCancel} onPress={()=>{
            this.BottomMenuRef?.showPopUp(false);
          }}>
            <Text style={BottomMenuStyles.txtBtnListPopupCancel}>キャンセル</Text>
          </TouchableOpacity>
        </BottomMenu>
        
        <Popup ref={ref=>this.messageExchangePopup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.messageExchangePopup?.hidePopup();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12}}>
            <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
              <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848'}}>お互いにやり取りしていない為、画像は送れません</Text>
            </View>
            <TouchableOpacity style={{padding:15, width:'100%', textAlign:'center'}} onPress={()=>{
              this.messageExchangePopup?.hidePopup();
             }}>
              <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7',textAlign:'center'}}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>

        <Popup ref={ref=>this.errorPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.errorPopup?.hidePopup();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12}}>
            <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
              <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848'}}>メッセージが入力されていません</Text>
            </View>
            <TouchableOpacity style={{padding:15, width:'100%', textAlign:'center'}} onPress={()=>{
              this.errorPopup?.hidePopup();
             }}>
              <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7',textAlign:'center'}}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>

        <Popup ref={ref=>this.ageVerficationPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.ageVerficationPopup?.hidePopup();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12}}>
          <View style={{paddingTop:25, paddingBottom:10, paddingLeft:15, paddingRight:15, width:'100%', textAlign:'center'}}>
              <Text style={{fontSize:20, fontWeight:'bold', lineHeight:24, color:'#59C7C9', textAlign:'center'}}>メッセージを閲覧するには{"\n"}年齢確認が必要です</Text>
            </View>
            <View style={{paddingTop:10, paddingBottom:10, paddingLeft:15, paddingRight:15, width:'100%', textAlign:'center'}}>
              <Text style={{fontSize:14, lineHeight:24, color:'#484848', textAlign:'center'}}>秩序とモラルを保つために{"\n"}お相手とのメッセージのやり取りは{"\n"}年齢確認が必要となります</Text>
            </View>
            <View style={{width:'80%',paddingBottom:20, paddingTop: 10,justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center'}}>
              <TouchableOpacity style={styles.btnBlue} onPress={()=>{
                this.ageVerficationPopup?.hidePopup();
                this.navigation.navigate('Auth',{ screen:'Identification'});
              }}>
                <Text style={styles.txtBtnBlue}>年齢確認に進む</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.btnCancel} onPress={()=>this.ageVerficationPopup?.hidePopup()}>
                <Text style={styles.txtBtnCancel}>閉じる</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Popup>

      </KeyboardAvoidingView>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

class MyMessage extends Component {
  
  renderMessageType = (item)=>{
    const messageType = parseInt(item.messageType)
    switch(messageType){
      case 0:
        if(item.stamp){
          if(item.stamp.indexOf('good_from_man') != -1) {
            return (<Image style={styles.imgMsg} resizeMode="cover" source={require('../../assets/images/good_from_man.png')} />);
          }else{
            return (<Image style={styles.imgMsg} resizeMode="cover" source={require('../../assets/images/good_from_woman.png')} />);
          }
        }else if(item.message!=undefined && item.message!=''){
          return (
            <View style={{maxWidth: screenWidth-100}}>
              <View style={[styles.bubbleTxtMsg,{backgroundColor:'#5cc8ca',maxWidth: (275 / 375 * screenWidth)}, {alignSelf: "flex-end"}]}>
                <Hyperlink linkStyle={{color: '#2980b9', fontWeight: 'bold'}} 
                  onPress={(url, text) => {
                    Linking.openURL(url)
                    console.log(url);
                  }}>
                  <Text selectable style={[styles.txtMsg,{color:'#fff'}]}>{item.message}</Text>
                </Hyperlink>
              </View>
            </View>
          );
        }

      case 4:
        if ((item.deleteFlag!=undefined) && (item.deleteFlag==1)) {
          return (
            <View style={styles.imageBase}>
                <View style={styles.imageDel}>
                  <Text style={styles.txtImageDel}>あなたがメッセージを削除しました</Text>
                </View>
            </View>
          );
        } else if(item.attachImageUrl!=undefined && item.attachImageUrl!=''){
          return (
            <RightSlidePopup title="" isImage={true}  showChild={true} component={
              <View style={styles.container}>
                <Image resizeMode="contain" style={{width:screenWidth,height:'100%'}} source={{uri : item.attachImageUrl}} />
              </View>
            }>
              <Image style={styles.imgMsg} source={{uri : item.attachImageUrl}} resizeMode="cover" />
              <TouchableOpacity style={styles.selectDelBtn} onPress={()=>{
                const params = {selectItem: item};
                this.props.deleteChatImage(params);
              }}>
                <Image style={styles.selectDelBtnImg} resizeMode="cover" source={require('../../assets/images/icon/imageDelete.png')} />
              </TouchableOpacity>
            </RightSlidePopup>
          );
        }
      
      case 1:
      case 2:
      case 3:
      case 5:
      default:
        if(item.message!=undefined && item.message!=''){
          return (
            <View style={{maxWidth: screenWidth-100}}>
              <View style={[styles.bubbleTxtMsg,{backgroundColor:'#5cc8ca', maxWidth: (275 / 375 * screenWidth)}, {alignSelf: "flex-end"}]}>
                <Hyperlink linkStyle={{color: '#2980b9', fontWeight: 'bold'}} 
                  onPress={(url, text) => {
                    Linking.openURL(url)
                    console.log(url);
                  }}>
                  <Text selectable style={[styles.txtMsg,{color:'#fff'}]}>{item.message}</Text>
                </Hyperlink>
              </View>  
            </View>            
          );
        }
    }
  }

  render() {
    const { item, index } = this.props;
    const messageType = parseInt(item.messageType)
    return (      
       <View>
       {messageType!= 1 ?
         <View style={[styles.myMessageContainer,{marginBottom:index==0?10:0}]}>
          {item?.deleteFlag!=1 ?
            <View style={[styles.blogTime,{left:-32, paddingRight: 10, paddingBottom: 5}]}>
              {item?.alreadyType==1 && <Text style={[styles.txtAlreadyRead,{textAlign:'right'}]}>既読</Text>}
                <Text style={[styles.txtTime,{textAlign:'right'}]}>{Moment(item.dateTime).format("HH:mm")}</Text>
            </View> 
         :null}
         {this.renderMessageType(item)}
        </View>
       :
         <View style={{maxWidth: screenWidth}}>
           <View style={styles.matchingContainer}>
             <Text style={styles.matchingDateTxt}>{Moment(item.dateTime).format("HH:mm")}</Text>
             <Text style={styles.matchingTitle}>マッチングが成立しました</Text>
             <Text style={styles.matchingTxt}>お互いにいいねしたので</Text>
             <Text style={styles.matchingTxt}>メッセージのやり取りを始めましょう</Text>
           </View>
         </View>
       }
     </View>
    );
  }
}

class OtherMessage extends Component {

  renderMessageType = (item)=>{
    const messageType = parseInt(item.messageType)
    switch(messageType){
      case 0:
        if(item.stamp){
          if(item.stamp.indexOf('good_from_man') != -1) {
            return (<Image style={styles.imgMsg} resizeMode="cover" source={require('../../assets/images/good_from_man.png')} />);
          }else{
            return (<Image style={styles.imgMsg} resizeMode="cover" source={require('../../assets/images/good_from_woman.png')} />);
          }
        }else if(item.message!=undefined && item.message!=''){
          return (
            <View style={styles.otherMessageSection}>
              <View style={[styles.bubbleTxtMsg,{backgroundColor:'#eef1f2', maxWidth: (230 / 375 * screenWidth)}, {alignSelf: "flex-start"}]}>
                <Hyperlink linkStyle={{color: '#2980b9', fontWeight: 'bold'}}
                    onPress={(url, text) => {
                      Linking.openURL(url)
                      console.log(url);
                  }}>
                  <Text selectable style={[styles.txtMsg,{color:'#484848'}]}>{item.message}</Text>
                </Hyperlink>
              </View>
            </View>
          );
        }

      case 4:
        if ((item.deleteFlag!=undefined) && (item.deleteFlag==1)) {
          return (
            <View style={[styles.imageBase, {width:screenWidth}]}>
                <View style={[styles.imageDel, {marginLeft:-50}]}>
                  <Text style={styles.txtImageDel}>お相手がメッセージを削除しました</Text>
                </View>
            </View>
          );
        } else if(item.attachImageUrl!=undefined && item.attachImageUrl!=''){
          return (
            <RightSlidePopup title="" isImage={true}  showChild={true} component={
              <View style={styles.container}>
                <Image resizeMode="contain" style={{width:screenWidth, height:'100%'}} source={{uri : item.attachImageUrl}} />
              </View>
              }>
              <Image style={[styles.imgMsg,{marginTop:0}]} source={{uri : item.attachImageUrl}} resizeMode="cover" />
            </RightSlidePopup>
          );
        }

      case 1:
      case 2:
      case 3:
      case 5:
      default:
        if(item.message!=undefined && item.message!=''){
          return (            
            <View style={styles.otherMessageSection}>
              <View style={[styles.bubbleTxtMsg,{backgroundColor:'#eef1f2', maxWidth: (230 / 375 * screenWidth)}, {alignSelf: "flex-start"}]}>
                <Hyperlink linkStyle={{color: '#2980b9', fontWeight: 'bold'}}
                    onPress={(url, text) => {
                      Linking.openURL(url)
                      console.log(url);
                  }}>
                  <Text selectable style={[styles.txtMsg,{color:'#484848'}]}>{item.message}</Text>
                </Hyperlink>
              </View>
            </View>
          );
        }
    }
  }

  render() {
    const { profile, item, index, paymentFlag, account, sex, navigation, validateAuthen, showAgeVerficationPopup , checkSMS} = this.props;
    const messageType = parseInt(item.messageType)

    // female
    if(sex==Constants.FEMALE){
      return (
        <View>
          {messageType!= 1 ?
          <View style={[styles.otherMessageContainer,{marginBottom:index==0?10:4}]}>
            {item?.deleteFlag!=1 ?
            <TouchableOpacity onPress={()=>{navigation.navigate('Profile',{memberId: profile.memberId, button:0})}}>
              <Image style={styles.avatar} source={{uri : profile?.tmMainImageUrl}} />
            </TouchableOpacity>
            :null}
            {item.isUncertifiedRestrict == 1?
              <View style={{marginTop: 35, marginLeft: 12, width: 217, height: 110}}>
                <ImageBackground 
                  style={{width: '100%', height: 110, justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center'}} 
                  imageStyle={{borderRadius:18}} 
                  source={require('../../assets/images/bg-blur.png')}
                >
                  <TouchableOpacity style={styles.btnBlueNoShadow} onPress={()=>{
                    if(!checkSMS()) {
                      return;
                    }
                    showAgeVerficationPopup();
                  }}>
                    <Text style={styles.txtBtnBlueNoShadow}>メッセージを開封する</Text>
                  </TouchableOpacity>
                </ImageBackground>
              </View>
            :
            this.renderMessageType(item)
            }
            <View style={[styles.blogTime,{right:-32, paddingLeft:10, paddingBottom: 5}]}>
              {item?.alreadyType==0 && (<Image style={{width:23, height:9}} source={require('../../assets/images/icon/ic-new.png')}/>)}
              <Text style={styles.txtTime}>{Moment(item.dateTime).format("HH:mm")}</Text>
            </View>
          </View>
          :
          <View style={{maxWidth: screenWidth}}>
            <View style={styles.matchingContainer}>
              <Text style={styles.matchingDateTxt}>{Moment(item.dateTime).format("HH:mm")}</Text>
              <Text style={styles.matchingTitle}>マッチングが成立しました</Text>
              <Text style={styles.matchingTxt}>お互いにいいねしたので</Text>
              <Text style={styles.matchingTxt}>メッセージのやり取りを始めましょう</Text>
            </View>
          </View>
          }
        </View>
      );
    }

    // male
    return (
      <View>
        {messageType!= 1 ?
        <View style={[styles.otherMessageContainer,{marginBottom:index==0?10:4}]}>
          {item?.deleteFlag!=1 || item?.isRestricted==1 ?
            <TouchableOpacity onPress={()=>{navigation.navigate('Profile',{memberId: profile.memberId, button:0})}}>
              <Image style={styles.avatar} source={{uri : profile?.tmMainImageUrl}} />
            </TouchableOpacity>
          :null}
          <>
          {item.isUncertifiedRestrict == 1?
          <View style={{marginTop: 35, marginLeft: 12, width: 217, height: 110}}>
            <ImageBackground 
              style={{width: '100%', height: 110, justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center'}} 
              imageStyle={{borderRadius:18}} 
              source={require('../../assets/images/bg-blur.png')}
            >
              <TouchableOpacity style={styles.btnBlueNoShadow} onPress={()=>{
                if(!checkSMS()) {
                  return;
                }
                showAgeVerficationPopup();
              }}>
                <Text style={styles.txtBtnBlueNoShadow}>メッセージを開封する</Text>
              </TouchableOpacity>
            </ImageBackground>
          </View>
          :
          <View>
            {item?.isRestricted==1 ? 
              <View style={{marginTop: 35, marginLeft: 12, width: 217, height: 110}}>
                <ImageBackground 
                  style={{width: '100%', height: 110, justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center'}} 
                  imageStyle={{borderRadius:18}} 
                  source={require('../../assets/images/bg-blur.png')}
                >
                  <TouchableOpacity style={styles.btnOrange} onPress={()=>{
                    validateAuthen();
                  }}>
                    <Text style={styles.txtBtnOrange}>メッセージを見る</Text>
                  </TouchableOpacity>
                  <Text style={{textAlign:'center',width:'100%', color:'#9aa5a7', fontSize:12, marginTop:10}}>
                    開封には{"\n"}有料会員登録が必要です
                  </Text>
                </ImageBackground>
              </View>
              :
              this.renderMessageType(item)
              }
          </View>
          }
          {item?.deleteFlag!=1 ?
            <View style={[styles.blogTime,{right:-32, paddingLeft:10, paddingBottom: 5}]}>
              {item?.alreadyType==0 && (<Image style={{width:23, height:9}} source={require('../../assets/images/icon/ic-new.png')}/>)}
              <Text style={styles.txtTime}>{Moment(item.dateTime).format("HH:mm")}</Text>
            </View>
          :null}
          </>
        </View>
        :
        <View style={{maxWidth: screenWidth}}>
          <View style={styles.matchingContainer}>
            <Text style={styles.matchingDateTxt}>{Moment(item.dateTime).format("HH:mm")}</Text>
            <Text style={styles.matchingTitle}>マッチングが成立しました</Text>
            <Text style={styles.matchingTxt}>お互いにいいねしたので</Text>
            <Text style={styles.matchingTxt}>メッセージのやり取りを始めましょう</Text>
          </View>
        </View>
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    height:'100%',
    backgroundColor:'#ffffff',
  },
  bottomTab:{
    height:HeaderOptions.bottomChatHeight,
    alignItems:'center',
    justifyContent:'center',
    alignSelf:'center',
    width:'100%',
    backgroundColor:'#f5f5f5',
    borderTopColor:'#d8d8d8', 
    borderTopWidth:1,
    ...Platform.select({
      ios: {
      },
      android: {
      },
      default:{
      }
    }),
  },
  icShowPopup:{
    marginRight:16,
    fontSize:30,
    color:'#484848',
  },
  thumbAvatar:{
    width:32,
    height:32,
    borderRadius:9999,
  },
  avatar:{
    width:60,
    height:60,
    borderRadius:5,
  },
  date:{
    marginTop:12,
    marginBottom:12,
  },
  txtDate:{
    fontSize:12,
    fontWeight:'normal',
    lineHeight:19,
    color:'#9aa5a7',    
    textAlign:'center',
  },
  blogTime:{
    position:'absolute',
    bottom:0,
  },
  txtAlreadyRead:{
    fontSize:7,
    lineHeight:9,
    color:'#68787b',
  },
  txtTime:{
    fontSize:9,
    lineHeight:14,
    color:'#68787b',
  },
  bubbleTxtMsg:{
    paddingTop:8,
    paddingRight:12,
    paddingBottom:8,
    paddingLeft:12,
    borderRadius:16,
    ...Platform.select({
      web:{
        height:'auto'
      }
    })
  },
  txtMsg:{
    fontSize:14,
    lineHeight:19,
    flexWrap:'wrap',
  },
  imgMsg:{
    marginTop:35,
    width:130,
    height:130,
    borderRadius:18,
    marginLeft:10
  },
  btnBottom:{
    flexDirection:'row',
  },
  itemBtnBottom:{
    flexDirection:'row',
    marginLeft:5,
    marginRight:10,
  },
  btnBtnBottom:{
    padding: 5,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
  },
  icBtnBottom:{
    width:22,
    height:18,
    justifyContent:'center',
  },
  txtBtnBottom:{
    fontSize:10,
    fontWeight:'bold',
    lineHeight:15,
    color:'#484848',
  },
  textareaBtnBottom:{
    marginTop:10,
    marginBottom:5,
    width:'90%',
    backgroundColor:'#fff',
    borderWidth:2,
    borderColor:'#dbdbdc',
    borderRadius:5,
    ...Platform.select({
      web:{
        padding:5,
      },
      android:{
        padding:5,
      },
      ios:{
        paddingLeft:5
      }
    }),
  },
  textAreaStyle: {
    height:75,
    ...Platform.select({
      ios: { },
      android: { textAlignVertical:'top' },
      default: {},
    }),
  },
  textareaBtnBottom2:{
    justifyContent:'flex-start',
    padding:5,
    width:285,
    height:75,
    backgroundColor:'#f2f2f2',
    borderRadius:8,
  },
  btnSendMsg: {
    justifyContent:'center',
    alignItems:'center',
    alignSelf:'center',
    width:'100%',
    textAlign:'center',
  },
  btnBtnSendMsg: {
    //width:'12%',
    minWidth:85,
    flexDirection:'row',
    padding: 5,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    backgroundColor:'#5cc8ca',
    borderRadius:999
  },
  txtBtnSendMsg: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:22,
    color:'#ffffff',
    textAlign:'center',
  },  
  btnArea: {
    paddingTop:25,
    paddingBottom:25,
    paddingRight:27,
    paddingLeft:27,
  },
  btnBlue:{
    marginBottom:10,
    width:'100%',
    padding:8,
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    // shadowColor: "#10a4c3",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 1,
    // shadowRadius: 1,
    // elevation: 1,
  },
  txtBtnBlue:{
    fontSize:18,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center'
  },
  btnCancel:{
    width:'70%',
    marginTop:10,
    padding:8,
    height:35,
    alignItems:"center",
    justifyContent:"center",
    backgroundColor:'#f2f2f2',
    borderRadius:999,
    letterSpacing:2,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#383838',
    textAlign:'center',
  },
  btnOrange:{
    padding:8,
    width:'90%',
    height:37,
    backgroundColor:'#ffa246',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnOrange:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:21,
    color:'#fff',
    textAlign:'center',
  },
  myMessageContainer:{
    flex:1, 
    alignSelf:'flex-end',
    flexDirection:'row',
    marginTop:12,
    marginRight:12,
    marginLeft:12,
    position:'relative',
    minHeight:24
  },
  otherMessageContainer:{
    flex:1, 
    alignSelf:'flex-start', 
    flexDirection:'row',
    marginTop:12,
    marginRight:12,
    marginBottom:4,
    marginLeft:12,
    minHeight:24
  },
  otherMessageSection:{
    marginLeft:10,
    justifyContent:'flex-end',
    //flex:1,
    maxWidth: screenWidth-145,
  },
  backToBottomContainer:{
    justifyContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    position:'absolute', 
    bottom:80, 
    flexDirection:'row'
  },
  backToBottomTxt:{
    textAlign:'center',
    paddingTop:2,
    paddingBottom:5,
    paddingLeft:5,
    paddingRight:5, 
    justifyContent:"center"
  },
  btnBtnFav: {
    width:20,
    height:19,
    //justifyContent:'center',
    //alignContent:'center',
    //alignItems:'center',
  },
  webChatArea:{
    height:chatAreaHeight,
  },
  appChatArea:{
    flex:1,
    justifyContent:'space-between',
  },
  selectDelBtn:{
    ...Platform.select({
      ios:{
        position:'absolute',
        right:0,
        top:0,
        marginTop:25,
      },
      android:{
        position:'absolute',
        right:0,
        top:0,
        marginTop:25,
      },
      default:{
        marginTop:-138,
        marginBottom:120,
        width:'100%',
        height:'100%',
        alignContent:'flex-end',
        alignItems:'flex-end',
      }
    })
    
  },
  selectDelBtnImg:{
    width:20,
    height:20,
    backgroundColor:'#888',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    borderRadius:999,
  },
  imageBase:{
    flex:1,
    width:screenWidth,
    paddingLeft:30,
    height:30,
    paddingTop:5,
    alignSelf:'center',
    backgroundColor:'#ffffff',
    alignContent:'center',
    alignItems:'center',
  },
  imageDel:{
    width:180,
    height:20,
    backgroundColor:'#888',
    alignSelf:'center',
    borderRadius:999,
  },
  txtImageDel:{
    fontSize:10,
    lineHeight:20,
    fontWeight:'bold',
    color:'#fff',
    textAlign:'center',
  },
  templateItem:{
    width: '95%',
    height: 29,
    marginTop: 10,
    marginLeft: '8%',
    backgroundColor:'#FFF',
    borderWidth: 2,
    borderColor: "#67CBCD",
    borderStyle: "solid",
    borderRadius:4,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop:2,
    paddingBottom:2,
    marginRight: '8%',
    justifyContent: 'center'
  },
  templateItemText:{
    fontWeight:'bold',
    color:'#67CBCD',
    textAlign:'left',
  },
  btnBlueNoShadow:{
    width:'90%',
    padding:8,
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",

  },
  txtBtnBlueNoShadow:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:21,
    color:'#fff',
    textAlign:'center'
  },
  matchingContainer:{
    backgroundColor:'#EEF1F2', 
    width:'95%',
    marginTop: 10,
    marginBottom:10, 
    padding: 8, 
    borderRadius:10, 
    alignSelf: "center"
  },
  matchingDateTxt:{
    alignSelf: "center", 
    color:'#363535',
    marginBottom: 1
  },
  matchingTitle:{
    alignSelf: "center", 
    fontWeight:'bold', 
    color:'#fa1594',
    marginBottom: 1
  },
  matchingTxt:{
    alignSelf: "center", 
    color:'#363535'
  },
  noMatchTitle:{
    alignSelf: "center", 
    fontWeight:'bold', 
    color:'#363535',
    marginBottom: 1
  },
});